import getGlobalThis from './getGlobalThis';

let overriddenHostname: string;
export const setCurrentHostname = (hostname: string) => {
  overriddenHostname = hostname;
};

export const getCurrentHostname = (): string =>
  overriddenHostname || getGlobalThis().location.hostname;

export const getCurrentUrl = (): string => getGlobalThis().location.href;

export const getCurrentEnvironment = () => {
  const hostname = getCurrentHostname();
  const matchBed = /^bed-([^.]+)\.api\.no$/.exec(hostname);
  const matchAidSnaps = /www-([^.]+).aid.no/.exec(hostname);
  const match = /\.([^.]+)\.api\.no/.exec(hostname);
  if (matchBed) {
    return matchBed[1];
  }
  if (matchAidSnaps) {
    return matchAidSnaps[1];
  }
  if (match) {
    return match[1];
  }
  return null;
};

export const getCurrentSiteDomain = () =>
  getCurrentHostname().replace(/\.[^.]+\.api\.no/, '');

export const getAidDomain = (): string => {
  const environment = getCurrentEnvironment();
  let domain = 'www.aid.no';
  if (environment === 'localhost') {
    domain = 'www.aid.no.localhost.api.no';
  } else if (environment) {
    domain = `www-${environment}.aid.no`;
  }
  return domain;
};

export function isOnAidNo(): boolean {
  return getCurrentHostname() === getAidDomain();
}

const testSites = [
  'www.tangotidende.no',
  'www.salsaposten.no',
  'www.rumbarapporten.no',
  'www.polkaposten.no',
];
export function isOnTestSite(): boolean {
  return testSites.includes(getCurrentHostname());
}
